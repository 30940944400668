.remote {
  height: 100%;
  width: 100%;
  background-color: var(--orange);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Actay Wide';
  padding: 16px 24px 95px 24px;
  box-sizing: border-box;
}

.remote.disabled {
  background-color: var(--gray);
}

.remote.inprogress {
  background-color: var(--white);
}

.remote-inprogress__image-container {
  min-width: 187px;
  box-sizing: border-box;
  min-height: 187px;
  margin: 2% auto 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.remote-inprogress__title {
  width: 100%;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 2.3rem;
  margin: 0;
  font-family: 'Actay Wide', sans-serif;
  color: var(--blue);
  text-align: center;
}

.remote-inprogress__title_colored {
  color: #ffbe30;
}

.remote-inprogress__text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: var(--blue);
  text-align: center;
  margin-bottom: 20px;
  width: 90%;
}

.remote-inprogress__button-container {
  margin-top: 20px;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.remote__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.remote__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.remote__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remote__container.inprogress {
  height: 100%;
  width: 90%;
}

.remote__speed {
  border: 1px solid var(--white);
  width: 100%;
  margin-top: 3vh;
  border-radius: 12px;
  font-family: 'Actay', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--white);
  padding: 5px 10px;
  box-sizing: border-box;
  /* margin-top: 7px; */
}

.remote__speed.no-own {
  background-color: var(--white);
  color: var(--orange);
}

.remote__speed.limited-error {
  background-color: var(--white);
  color: var(--orange);
}

.remote__link {
  text-decoration: underline;
  cursor: pointer;
}

.remote__info {
  display: flex;
  justify-content: space-around;
  min-height: 137px;
  width: 100%;
  margin-top: 1.8rem;
}

.remote__info-gb {
  height: 100%;
  width: 51%;
}

.remote__info-gb-left {
  height: 49%;
  background-color: var(--white);
  color: var(--orange);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}

.remote__info-gb-used {
  height: 49%;
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.remote__info-users {
  height: 100%;
  width: 47%;
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.remote__users-item.active {
  background-color: var(--white);
  color: var(--orange);
}

.remote__info-title {
  font-weight: 700;
  font-size: 2rem;
  padding-bottom: 10px;
}

.remote__info-title.disabled {
  color: var(--gray);
}

.remote__info-title-big {
  font-weight: 700;
  font-size: 3.8rem;
}

.remote__info-subtitle {
  font-size: 1rem;
  text-align: center;
}

.remote__info-subtitle.disabled {
  color: var(--gray);
}

.remote__users-content {
  position: relative;
}

.remote__users {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1.7px solid var(--white);
  border-radius: 14px;
  max-height: 193px;
}

.remote__users.margin-bottom {
  margin-bottom: 40px;
}

.remote__users-titles {
  width: 90%;
  display: flex;
  justify-content: space-between;
  color: var(--white);
  /* margin-right: 35px; */
}

.remote__users-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remote__users-list {
  width: 90%;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.remote__users-list::-webkit-scrollbar {
  display: none;
}

.remote__users-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  border: 1.7px solid var(--white);
  border-radius: 14px;
  min-height: 42px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 30px;
  box-sizing: border-box;
}

.remote__users-item.hidden {
  display: none;
}

.remote__users-item-more div {
  width: 4px;
  height: 4px;
  background-color: var(--white);
  border-radius: 100%;
  margin-top: 2px;
}

.remote__users-item-more.active div {
  background-color: var(--orange);
}

.remote__users-item-name {
  /* margin-right: 20px; */
  border: none;
  background-color: var(--orange);
  color: var(--white);
  width: 30%;
}

.remote__users-item-name.active {
  background-color: var(--white);
  color: var(--orange);
}

.remote__users-item-name.disabled {
  background-color: var(--gray);
}

.remote__users-item-got {
  width: 40%;
}

.remote__users-item-got-input {
  border: none;
  background-color: var(--orange);
  color: var(--white);
  width: 40%;
  margin-right: 3px;
}

.remote__users-item-got-input.removed {
  width: 100%;
}

.remote__users-item-got-input.active {
  background-color: var(--white);
  color: var(--orange);
}

.remote__users-item-got-input.disabled {
  background-color: var(--gray);
}

.remote__users-item-got__gb.hidden {
  display: none;
}

.remote__popup-message {
  color: var(--white);
  text-align: center;
}

.remote__info-connection {
  color: var(--white);
  text-align: center;
}

.remote__settings-button {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.remote__settings-button__popup {
  z-index: 1000;
  position: absolute;
  right: 14px;
  top: -10px;
  transform: translateY(30%);
  /* width: calc(100% + 3px); */
  min-width: 55%;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 5px 10px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  color: var(--orange);
  background-color: var(--white);
}

.remote__popup-info {
  color: var(--white);
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
}

.remote__users-item-got-input ::-webkit-outer-spin-button,
.remote__users-item-got-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* .remote__users-item-got-input[type=number] {
  -moz-appearance: textfield;
} */

/* @media screen and (max-height: 675px) {
    .remote__users {
        margin-bottom: 30px;
    }
} */

@media screen and (max-height: 665px) {
  .remote__info {
    min-height: 127px;
  }
}

@media screen and (max-height: 605px) {
  .remote__info {
    min-height: 107px;
  }

  .remote__users-item {
    min-height: 37px;
  }

  .remote__users {
    max-height: 175px;
  }

  .remote__settings-button {
    margin-top: 0;
  }
}

.remote__users-container {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 10%;
}
